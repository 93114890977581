import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const PrivacyPage = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ py: 4 }}>
      <Container maxWidth="md">
        <Typography variant="h3" sx={{ textAlign: "center", mb: 4 }}>
          {t("footer.privacyPolicy")}
        </Typography>
        <Typography variant="h5" sx={{ mt: 4, mb: 1 }}>
          Qui sommes-nous ?
        </Typography>
        <Typography variant="body1" paragraph>
          L’adresse de notre site est :{" "}
          <a
            href="https://www.2minteractive.ch"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.2minteractive.ch
          </a>
          .
        </Typography>

        <Typography variant="h5" sx={{ mt: 4, mb: 1 }}>
          Données Collectées
        </Typography>
        <Typography variant="body1" paragraph>
          Notre application ne collecte pas de données personnelles à
          l'exception du nom d'utilisateur lors de la sauvegarde d'un PDF. Le
          jeton JWT utilisé pour l'authentification est stocké localement sur
          l'appareil de l'utilisateur via le localStorage.
        </Typography>
        <Typography variant="h5" sx={{ mt: 4, mb: 1 }}>
          Utilisation des Services Alfresco
        </Typography>
        <Typography variant="body1" paragraph>
          En utilisant notre application, vous consentez à l’utilisation des
          services Alfresco pour l'authentification et la gestion des documents.
        </Typography>
        <Typography variant="h5" sx={{ mt: 4, mb: 1 }}>
          Conservation des Données
        </Typography>
        <Typography variant="body1" paragraph>
          Les données sont conservées aussi longtemps que l'utilisateur ne les
          supprime pas de son IndexedDB local. Le jeton JWT est conservé dans le
          localStorage pour permettre une authentification continue tant qu'il
          n'est pas supprimé par l'utilisateur.
        </Typography>
        <Typography variant="h5" sx={{ mt: 4, mb: 1 }}>
          Sécurité
        </Typography>
        <Typography variant="body1" paragraph>
          Nous nous engageons à protéger les données stockées localement sur
          l'appareil de l'utilisateur, mais nous recommandons aux utilisateurs
          de veiller à la sécurité de leurs appareils et de supprimer les
          données s'ils le jugent nécessaire.
        </Typography>
        <Typography variant="h5" sx={{ mt: 4, mb: 1 }}>
          Partage de Données
        </Typography>
        <Typography variant="body1" paragraph>
          Nous ne partageons aucune donnée avec des tiers, à l'exception du nom
          d'utilisateur envoyé au moment de la sauvegarde d'un PDF. Les données
          sont transmises aux services Alfresco lors de la finalisation des
          documents.
        </Typography>
        <Typography variant="h5" sx={{ mt: 4, mb: 1 }}>
          Droits des Utilisateurs
        </Typography>
        <Typography variant="body1" paragraph>
          En utilisant les services Alfresco, vous disposez des droits d'accès,
          de rectification et de suppression de vos données via Alfresco. Pour
          plus de détails, veuillez consulter la politique de confidentialité
          d'Alfresco.
        </Typography>
      </Container>
    </Box>
  );
};

export default PrivacyPage;
