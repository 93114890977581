import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "context/SnackbarContext";
import { Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import api from "services/api";
import { RootState } from "services/store";
import { useDialog } from "context/DialogContext";
import imageAccounting from "assets/images/ACCOUNTING.png";
import Image from "components/core/Image/Image";

const CresusAction: React.FC = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { showDialog, forceCloseDialog } = useDialog();
  const hasCresus = useSelector((state: RootState) => state.user.hasCresus);
  const [enableCresusButton, setEnableCresusButton] = useState(false);
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);
  const checkInterval = useRef<NodeJS.Timeout | null>(null);

  const checkCresusAvailability = async () => {
    try {
      const response = await api.get("/cresus/download?action=check");
      if (response.data) {
        setEnableCresusButton(true);
        if (checkInterval.current) {
          clearInterval(checkInterval.current);
          checkInterval.current = null;
        }
      } else {
        if (!checkInterval.current) {
          checkInterval.current = setInterval(() => {
            checkCresusAvailability();
          }, 5 * 60 * 1000);
        }
        setEnableCresusButton(false);
      }
    } catch (error) {
      console.error("Error checking Cresus availability:", error);
    }
  };

  useEffect(() => {
    checkCresusAvailability();

    return () => {
      if (checkInterval.current) {
        clearInterval(checkInterval.current);
      }
    };
  }, [enableCresusButton]);

  const handleMouseOver = () => {
    if (!enableCresusButton && !hoverTimeout.current) {
      checkCresusAvailability();
      hoverTimeout.current = setTimeout(() => {
        hoverTimeout.current = null;
      }, 10000);
    }
  };

  const extractFilename = (contentDisposition: string) => {
    // Regex to match filename within the Content-Disposition header
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  
    const matches = filenameRegex.exec(contentDisposition);
  
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  
    return 'downloaded-cresus.txt';
  };

  const handleCresus = async () => {
    const confirm = await showDialog({
      type: "confirmation",
      title: "Confirmation",
      message: t("promptCresus"),
      severity: "info",
    });

    if (!confirm) return;

    try {
      showDialog({
        type: "lock",
        title: t("headerMsg.loading"),
        message: t("headerMsg.loading"),
        severity: "info",
        progress: true,
      });

      const response = await api.get("/cresus/download?action=download", {
        responseType: "blob",
      });

      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const contentDisposition = response.headers["content-disposition"];
        let filename = extractFilename(contentDisposition);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();

        showSnackbar({
          message: t("fileCresusDownloadedSuccessfully"),
          severity: "success",
        });

        checkCresusAvailability();
      } else {
        showSnackbar({
          message: t("error.cannotDownloadCresusFile"),
          severity: "error",
        });
      }
    } catch (error: any) {
      console.error("Error downloading Cresus file:", error);
      if (error?.response?.status === 403) {
        showSnackbar({
          message: t("error.cannotDownloadCresusFileNoPermissions"),
          severity: "error",
        });
      } else if (error?.response?.status === 425) {
        showSnackbar({
          message: t("error.cannotDownloadCresusFileNoInputFiles"),
          severity: "error",
        });
      } else {
        showSnackbar({
          message: t("error.cannotDownloadCresusFile"),
          severity: "error",
        });
      }
    } finally {
      forceCloseDialog();
    }
  };

  return (
    <>
      {hasCresus && (
        <div onMouseOver={handleMouseOver} style={{ display: "inline" }}>
          <Tooltip title={t("tooltip.exportCresus")} arrow>
            <Button
              disabled={!enableCresusButton}
              onClick={handleCresus}
              variant="contained"
              component="label"
              startIcon={
                <Image style={{ backgroundImage: `url(${imageAccounting})` }} />
              }
              size="large"
              sx={{ my: 0.5, height: 40, width: 30 }}
            />
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default CresusAction;
