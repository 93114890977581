// components/core/Image/Image.tsx

import styled from "@emotion/styled";

const Image = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  margin: 5,
});

export default Image;
