import React from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import imageLogo from "assets/images/2mInteractiveLogo.png";

interface InfoModalProps {
  open: boolean;
  handleClose: () => void;
}

const InfoModal: React.FC<InfoModalProps> = ({ open, handleClose }) => {
  const { t } = useTranslation(); // Initialize the translation function

  const author = process.env.REACT_APP_SOFTWARE_AUTHOR;
  const software = process.env.REACT_APP_SOFTWARE;
  const version = process.env.REACT_APP_VERSION;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          width: "354px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          py: 6,
          pb: 4,
          px: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: 1,
        }}
      >
        <IconButton
          autoFocus
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>

        <img
          src={imageLogo}
          alt="logo"
          style={{ height: 128, marginBottom: 32 }}
        />

        {/* Software Name */}
        <Typography variant="caption" sx={{ color: "text.secondary" }}>
          {t("modal.software")}
        </Typography>
        <Typography id="modal-title" variant="h6" sx={{ mb: 2 }}>
          {software}
        </Typography>

        {/* Developed by Text */}
        <Typography variant="caption" sx={{ color: "text.secondary" }}>
          {t("modal.developedBy")}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {author}
        </Typography>

        {/* Version */}
        <Typography variant="caption" sx={{ color: "text.secondary" }}>
          {t("modal.version")}
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          {version}
        </Typography>
      </Box>
    </Modal>
  );
};

export default InfoModal;
