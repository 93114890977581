// components/UserAvatarDisplay/UserAvatar.tsx

import React, { useState } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useSelector } from "react-redux";
import { RootState } from "services/store";
import UserInfo from "components/infos/UserInfo/UserInfo";

const UserAvatar: React.FC = () => {
  const username = useSelector((state: RootState) => state.user.username);
  const isLogged = useSelector((state: RootState) => state.user.isLogged);
  const [open, setOpen] = useState(false);

  // Check if the user is logged in; if not, return null
  if (!isLogged) return null;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minWidth: 120,
          ml: 0,
          mt:"8px",
          cursor: "pointer", // Indicate clickable action
        }}
        onClick={handleOpen} // Open modal on click
      >
        <Avatar sx={{ bgcolor: "#366193", width: 20, height: 20 }}>
          <AccountCircleIcon />
        </Avatar>
        <Typography
          variant="caption"
          title={username}
          sx={{ fontWeight: "bold", textAlign: "center" }}
        >
          {username.length > 10 ? `${username.slice(0, 7)}...` : username}
        </Typography>
      </Box>

      <UserInfo open={open} handleClose={handleClose} />
    </>
  );
};

export default UserAvatar;
