import React, { createContext, useContext, useState, ReactNode } from "react";
import { Snackbar, Alert, AlertTitle, Slide, SlideProps } from "@mui/material";

type SnackbarOptions = {
  message: string;
  title?: string;
  severity: "success" | "error" | "warning" | "info";
  duration?: number;
};

type SnackbarContextType = {
  showSnackbar: (options: SnackbarOptions) => void;
  closeSnackbar: () => void;
};

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [snackbarOptions, setSnackbarOptions] = useState<SnackbarOptions | null>(
    null
  );
  const [open, setOpen] = useState(false);

  const showSnackbar = (options: SnackbarOptions) => {
    setSnackbarOptions(options);
    setOpen(true);
  };

  const closeSnackbar = () => {
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, closeSnackbar }}>
      {children}
      {snackbarOptions && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          autoHideDuration={snackbarOptions.duration || 6000}
          onClose={closeSnackbar}
          TransitionComponent={SlideTransition}
          sx={{ mt: 0 }}
          
        >
          <Alert
            onClose={closeSnackbar}
            severity={snackbarOptions.severity}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {snackbarOptions.title && (
              <AlertTitle>{snackbarOptions.title}</AlertTitle>
            )}
            {snackbarOptions.message}
          </Alert>
        </Snackbar>
      )}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = (): SnackbarContextType => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};
