import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "services/store";
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import CloseIcon from "@mui/icons-material/Close";
import { usePdfViewer } from "context/PdfViewerContext";
import { useTranslation } from "react-i18next";
import "./BottomSheetDialog.css";
import { setCurrentPage, setDisplayNavigator } from "features/appStateSlice";
import { IBarcodeListData } from "models/IBarcodeListData";

const BottomSheetDialog: FC = () => {
  const { t } = useTranslation();
  const currentPage = useSelector(
    (state: RootState) => state.appState.currentPage
  );
  const displayNavigator = useSelector(
    (state: RootState) => state.appState.displayNavigator
  );
  const dispatch = useDispatch();
  const { pdfViewer, removeAnnotation, goToPage } = usePdfViewer();
  const doctypes = useSelector((state: RootState) => state.doctypes.doctypes);

  const closeNavigator = () => {
    dispatch(setCurrentPage(null));
  };

  const documentInfo = useMemo(() => {
    if (!pdfViewer) return {};

    let firstPageNumber: number = 1;
    let lastPageNumber: number = pdfViewer.pageCount;
    let subject: string | undefined = undefined;
    let fields: any = [];

    let firstPage: any | undefined = undefined;
    let lastPage: any | undefined = undefined;

    if (!currentPage) return { firstPageNumber, lastPageNumber, subject, fields };

    const barcodes = pdfViewer.annotationCollection.filter(
      (annotation) => annotation.author.startsWith(process.env.REACT_APP_AUTHOR)
    );

    
  
    if (!barcodes || !barcodes.length) return { firstPageNumber, lastPageNumber, subject, fields };

    


    const sortedBarcodes = [...barcodes].sort(
      (a, b) => a.pageNumber - b.pageNumber
    );
    const firstPages = sortedBarcodes.filter(
      (barcode) => barcode.pageNumber + 1 <= currentPage
    );
    firstPage = firstPages[firstPages.length - 1];
    lastPage = sortedBarcodes.find(
      (barcode) => barcode.pageNumber + 1 > currentPage
    );

    if (firstPage) firstPageNumber = firstPage.pageNumber + 1;
    if (lastPage) lastPageNumber = lastPage.pageNumber;

    if (firstPage) {
      const authorInformations = firstPage.author
      
      const dataInformations = authorInformations.replace(`${process.env.REACT_APP_AUTHOR}|`, "").split("|");
      
      if (!dataInformations || dataInformations.length === 0)
        return { firstPageNumber, lastPageNumber, subject, fields };

      const dataType = dataInformations[0];

      if (!dataType)
        return { firstPageNumber, lastPageNumber, subject, fields };

      const regex = /^([A-Z]+)(_\d+)*$/;
      const match = dataType.match(regex);

      if (!match) return { firstPageNumber, lastPageNumber, subject, fields };

      const documentTypeKey = match[1];

      const doctype = doctypes.find((doc) => doc.Key === documentTypeKey);

      if (!doctype) return { firstPageNumber, lastPageNumber, subject, fields };

      subject = doctype.Title;

      for (let i = 1; i < dataInformations.length; i++) {
        const currentField = doctype.Fields[i - 1];
        if (currentField) {
          if (currentField.Display) {
            let data = dataInformations[i];
            if (currentField.Options) {
              const option = currentField.Options.find(
                (option: any) =>
                  option.Value.toLowerCase() === data.toLowerCase() ||
                  option.Label.toLowerCase() === data.toLowerCase()
              );
              if (option && option.Label) {
                data = option.Label;
              }
            }
            fields.push({
              key: currentField.Label,
              data: data,
            });
          }
        }
      }
    }

    return { firstPageNumber, lastPageNumber, subject, fields };
  }, [
    currentPage,
    pdfViewer?.pageCount,
    pdfViewer?.annotationCollection,
    pdfViewer?.annotationCollection?.length,
  ]);

  if (!pdfViewer) return null;
  if (!currentPage) return null;
  
  const barcodes = pdfViewer.annotationCollection.filter(
    (annotation) => annotation.author.startsWith(process.env.REACT_APP_AUTHOR)
  );

  if (!barcodes || !barcodes.length) return null;

  function onDeleteAnnotation(pageNumber: number | undefined): void {
    if (!pageNumber) return;
    if (!pdfViewer) return;
    const annotation = pdfViewer.annotationCollection.find(
      (annotation) => annotation.author.startsWith(process.env.REACT_APP_AUTHOR) && (annotation.pageNumber + 1) === pageNumber
    );
    if (!annotation) return;
    removeAnnotation(annotation.annotationId);
    dispatch(setCurrentPage(null));
  }

  return (
    <Box
      sx={{
        position: "relative",
        bottom: 0,
        zIndex: 1000,
        // opacity: 0.75,
        bgcolor: "background.paper",
        // transition: "opacity 0.1s ease-in-out", // Smooth transition for opacity change
        // "&:hover": {
        //   opacity: 1.0, // Change opacity on hover
        // },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          boxShadow: 2,
          bgcolor: "background.paper",
          width: "50%",
          left: "25%",
          bottom: 24,
          display: documentInfo && documentInfo.subject ? "block" : "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: "background.paper",
            pl: 3,
            pt: 3,
            pr: 2,
            pb: 2,
          }}
        >
          <Box
            sx={{
              flex: 1,
              // display: "flex", alignItems: "center"
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", display: "inline" }}
            >
              {documentInfo.subject !== null &&
              documentInfo.subject !== undefined
                ? `${documentInfo.subject || t("bottomSheet.undefined")}`
                : t("bottomSheet.undefined")}
            </Typography>
            <Typography variant="caption" sx={{ display: "inline", ml: 1 }}>
              {t("bottomSheet.page", {
                count:
                  documentInfo.firstPageNumber !== documentInfo.lastPageNumber
                    ? 2
                    : 1,
              })}{" "}
              {`${documentInfo.firstPageNumber} ${
                documentInfo.firstPageNumber !== documentInfo.lastPageNumber
                  ? `- ${documentInfo.lastPageNumber}`
                  : ""
              }`}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Tooltip title={t("tooltip.close")} arrow placement="top">
              <IconButton onClick={closeNavigator}>
                {/* <ExpandMoreIcon />  */}
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Box
          sx={{
            px: 3,
            py: 2,
            bgcolor: "background.paper",
            borderTop: "1px solid #ddd",
            borderBottom: "1px solid #ddd",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, textDecoration: "underline" }}
            >
              {t("bottomSheet.information")}
            </Typography>
          </Box>

          <Box>
            {documentInfo.fields.map(
              (field: { key: string; data: string }, index: number) => (
                <Box
                  key={index} // Key should be on the outer element
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Typography variant="body2">{field.key}</Typography>
                  <Typography variant="body2" align="right">
                    {field.data}
                  </Typography>
                </Box>
              )
            )}
          </Box>
        </Box>

        <Box
          sx={{
            bgcolor: "background.paper",
            px: 2,
            pb: 2,
            pt: 2,
            // borderTop: "1px solid #ddd",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Box>
                <Tooltip
                  title={t("tooltip.deleteDocument")}
                  arrow
                  placement="bottom"
                >
                  <IconButton
                  color="error"
                    onClick={() => onDeleteAnnotation(documentInfo.firstPageNumber)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BottomSheetDialog;
