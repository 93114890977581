import { useEffect, useRef, useState, useContext } from "react";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";

import "../../App.css";

import MainAction from "components/forms/MainAction/MainAction";
import SelectedTool from "components/tools/SelectedTool/SelectedTool";
import SyncfusionViewer from "components/tools/SyncfusionViewer/SyncfusionViewer";

import { useDialog } from "context/DialogContext";
import { useSnackbar } from "context/SnackbarContext";
import { PdfViewerProvider } from "context/PdfViewerContext";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "services/store";
import { QueryClient, QueryClientProvider } from "react-query";
import api from "services/api";
import { login, logout } from "features/userSlice";
import { jwtDecode } from "jwt-decode";
import { AppStatus, setStatus } from "features/appStateSlice";

import imageSplash from "assets/images/splashScreen.png";
import { setDoctypes } from "features/doctypesSlice";

interface RouteParams {
  ged: string;
}

const EDocIndexWeb = () => {
  const { t } = useTranslation();
  const { ged } = useParams<RouteParams>();
  const status = useSelector((state: RootState) => state.appState.status);
  const pdfFileURI = useSelector(
    (state: RootState) => state.appState.pdfFileURI
  );

  const dispatch = useDispatch();
  const block = useRef<boolean>(false);
  let history = useHistory();

  const { showDialog } = useDialog();
  const { showSnackbar } = useSnackbar();

  const queryClient = new QueryClient();

  const checkUser = async () => {
    const token = localStorage.getItem("token");
    if (token != null) {
      try {
        await api.get("/auth/check");
        const decodedToken: any = jwtDecode(token);
        dispatch(
          login({
            username: decodedToken.username,
            token: token,
          })
        );
        return true;
      } catch (ex) {
        console.error(ex);
        dispatch(logout());
        showSnackbar({
          message: t("header.authCheckError"),
          severity: "error",
        });
        localStorage.removeItem("token");
        history.push(`/${ged}/login`);
        return false;
      }
    } else {
      history.push(`/${ged}/login`);
    }
    return false;
  };

  const fetchUserConfig = async () => {
    try {
      let response = await api.get("/user-config");
      dispatch(
        login({
          displayBrWithClient: response.data.Data.QRAvecCodeClient,
          clientName: response.data.Data.ClientName,
          hasCresus: response.data.Data.HasCresus,
          code: response.data.Data.CodeClient,
          gedLink: response.data.Data.GedLink
        })
      );
      response = await api.get("/doctypes");
      dispatch(setDoctypes(response.data.Data));
    } catch (error: any) {
      console.error(error);
      showDialog({
        type: "lock",
        title: t("modal.errorOccurred"),
        message: error.response?.data?.Message || t("internalError"),
        severity: "error",
      });
    }
  };

  const alertUser = async (e: any) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  useEffect(() => {
    block.current = status === AppStatus.EDITING;
  }, [status]);

  useEffect(() => {
    const checkAndFetch = async () => {
      const connected = await checkUser();
      if (!connected) return;
      await fetchUserConfig();
    };

    dispatch(setStatus(AppStatus.NO_DOCUMENT));

    checkAndFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Prompt
        when={block.current}
        message={() => {
          return t("promptMessage");
        }}
      />
      <Box height="100%">
        <QueryClientProvider client={queryClient}>
          <PdfViewerProvider>
            <Grid container spacing={0} sx={{ height: "100%" }}>
              <Grid item xs={1}>
                <MainAction />
              </Grid>
              <Grid item xs={4} lg={4} xl={3} sx={{ maxHeight: "100%" }}>
                <SelectedTool />
              </Grid>
              <Grid
                item
                xs={7}
                lg={7}
                xl={8}
                sx={{
                  backgroundColor: "white",
                  backgroundImage: `url(${imageSplash})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "auto 85%",
                }}
              >
                <SyncfusionViewer />
              </Grid>
            </Grid>
          </PdfViewerProvider>
        </QueryClientProvider>
      </Box>
    </>
  );
};

export default EDocIndexWeb;
