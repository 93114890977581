import React, { useState } from "react";
import { Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import Image from "components/core/Image/Image";
import imageMin from "assets/images/MIN.png";
import imageMax from "assets/images/MAX.png";

const FullScreenAction: React.FC = () => {
  const { t } = useTranslation();
  const [isFullScreen, setFullScreen] = useState<boolean>(false);

  const toggleFullScreen = () => {
    if (isFullScreen) {
      document.exitFullscreen();
    } else {
      document.body.requestFullscreen();
    }
    setFullScreen(!isFullScreen);
  };

  return (
    <Tooltip
      title={
        isFullScreen ? t("header.closeFullScreen") : t("header.openFullScreen")
      }
      arrow
    >
      <Button
        onClick={toggleFullScreen}
        variant="contained"
        startIcon={
          <Image
            style={{
              backgroundImage: `url(${isFullScreen ? imageMin : imageMax})`,
            }}
          />
        }
        size="large"
        sx={{ ml: 1, height: 40, width: 30 }}
      />
    </Tooltip>
  );
};

export default FullScreenAction;
