import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import i18n from '../i18n'; // Ensure you import the i18n instance

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Accept': 'application/json',
    // 'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const token = localStorage.getItem('token');
    if (token) {
      request.headers.set('Authorization', `Bearer ${token}`);
    }

    const language = i18n.language;
    request.headers.set('Accept-Language', language);

    const ged = window.location.pathname.split('/')[1];
    if (ged) {
      request.headers.set('Selected-GED', ged);
    }

    return request;
  },
  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    return response;
  },
  (error: AxiosError): Promise<AxiosError> => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  }
);


export default api;
