import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationDE from "./locales/de/translation.json";
import translationIT from "./locales/it/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationDE,
  },
  it: {
    translation: translationIT,
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: 'customNavigatorDetector',
  lookup(options) {
    const detectedLanguage = navigator.language;
    return detectedLanguage.split('-')[0];
  },
});

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "de", "fr", "it"],
    resources,
    fallbackLng: "fr",
    detection: {
      order: [
        "customNavigatorDetector",
        "querystring",
        "cookie",
        "localStorage",
        "htmlTag",
        "path",
        "subdomain",
      ],
      caches: ["localStorage", "cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
