import { debounce } from 'lodash';
import { AnnotationType } from "@syncfusion/ej2-react-pdfviewer";

// Open the IndexedDB and handle schema updates
const openDB = () => {
  return new Promise<IDBDatabase>((resolve, reject) => {
    const request = indexedDB.open("eDocIndexDB", 1); // Renamed the database to "eDocIndexDB"
    request.onerror = (event) => reject(`Database error: ${event}`);
    request.onsuccess = (event) => resolve(request.result);
    request.onupgradeneeded = (event) => {
      const db = request.result;
      if (!db.objectStoreNames.contains("pdfs")) { // Renamed the table to "pdfs"
        db.createObjectStore("pdfs", { keyPath: "id" });
      }
      if (!db.objectStoreNames.contains("annotations")) { // Renamed the table to "annotations"
        db.createObjectStore("annotations", {
          keyPath: "id",
        });
      }
    };
  });
};

// Save PDF Blob to the "pdfs" table
const savePDFToDB = async (data: Blob) => {
  return new Promise<void>((resolve, reject) => { resolve() });
  const db = await openDB();
  return new Promise<void>((resolve, reject) => {
    const transaction = db.transaction(["pdfs"], "readwrite");
    const store = transaction.objectStore("pdfs");
    store.put({ id: "currentPDF", blob: data }); // Changed structure to "id, blob"

    if (process.env.REACT_APP_DEV_MODE === "true") {
      console.log("-------------------------------");
      console.log("Saving PDF");
      console.log("-------------------------------");
    }

    transaction.oncomplete = () => resolve();
    transaction.onerror = () => reject(transaction.error);
  });
};

// Debounce the save function for PDFs
const savePDFDebounced = debounce(savePDFToDB, 0);

// Save an annotation to the "annotations" table
const saveAnnotationToDB = async (id: string, annotationType: AnnotationType, options: any) => {
  return new Promise<void>((resolve, reject) => { resolve() });
  const db = await openDB();
  return new Promise<void>((resolve, reject) => {
    const transaction = db.transaction(["annotations"], "readwrite");
    const store = transaction.objectStore("annotations");
    store.put({ id, action: "add", annotationType, options });

    if (process.env.REACT_APP_DEV_MODE === "true") {
      console.log("-------------------------------");
      console.log("Saving Annotation");
      console.log("-------------------------------");
    }

    transaction.oncomplete = () => resolve();
    transaction.onerror = () => reject(transaction.error);
  });
};

// Get PDF Blob from the "pdfs" table
const getPDFFromDB = async () => {
  return new Promise<void>((resolve, reject) => { resolve() });
  const db = await openDB();
  return new Promise<any>((resolve, reject) => {
    const transaction = db.transaction(["pdfs"], "readonly");
    const store = transaction.objectStore("pdfs");
    const request = store.get("currentPDF");

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

// Get all annotations from the "annotations" table
const getAnnotationsFromDB = async () => {
  return new Promise<void>((resolve, reject) => { resolve() });
  const db = await openDB();
  return new Promise<any[]>((resolve, reject) => {
    const transaction = db.transaction(["annotations"], "readonly");
    const store = transaction.objectStore("annotations");
    const request = store.getAll();

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

const getAnnotationFromDB = async (id: string) => {
  return new Promise<void>((resolve, reject) => { resolve() });
  const db = await openDB();
  return new Promise<any>((resolve, reject) => {
    const transaction = db.transaction(["annotations"], "readonly");
    const store = transaction.objectStore("annotations");
    const request = store.get(id);

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

const updateAnnotationIdInDB = async (oldId: string, newId: string) => {
  return new Promise<void>((resolve, reject) => { resolve() });
  const db = await openDB();
  const annotation = await getAnnotationFromDB(oldId);
  await removeAnnotationFromDB(oldId);
  return new Promise<void>((resolve, reject) => {
    if (annotation) {
      const data = annotation.options.customStamps[0].customStampName.split("|")
      annotation.options.customStamps[0].customStampName = `${data[0]}|${newId}`
      const transaction = db.transaction(["annotations"], "readwrite");
      const store = transaction.objectStore("annotations");
      store.put({ ...annotation, id: newId });
      transaction.oncomplete = () => resolve();
      transaction.onerror = () => reject(transaction.error);
    } else {
      resolve();
    }
  });
};

// Remove a specific annotation from the "annotations" table by ID
const removeAnnotationFromDB = async (annotationId: string) => {
  return new Promise<void>((resolve, reject) => { resolve() });
  const db = await openDB();
  return new Promise<void>((resolve, reject) => {
    const transaction = db.transaction(["annotations"], "readwrite");
    const store = transaction.objectStore("annotations");
    store.delete(annotationId);

    if (process.env.REACT_APP_DEV_MODE === "true") {
      console.log("-------------------------------");
      console.log("Removing Annotation");
      console.log("-------------------------------");
    }

    transaction.oncomplete = () => resolve();
    transaction.onerror = () => reject(transaction.error);
  });
};

// Clear all annotations from the "annotations" table
const clearAnnotationsFromDB = async () => {
  return new Promise<void>((resolve, reject) => { resolve() });
  const db = await openDB();
  return new Promise<void>((resolve, reject) => {
    const transaction = db.transaction(["annotations"], "readwrite");
    const store = transaction.objectStore("annotations");
    store.clear();

    transaction.oncomplete = () => resolve();
    transaction.onerror = () => reject(transaction.error);
  });
};

// Clear the PDF from the "pdfs" table
const clearPDFInDB = async () => {
  return new Promise<void>((resolve, reject) => { resolve() });
  const db = await openDB();
  return new Promise<void>((resolve, reject) => {
    const transaction = db.transaction(["pdfs"], "readwrite");
    const store = transaction.objectStore("pdfs");
    store.delete("currentPDF");

    transaction.oncomplete = () => resolve();
    transaction.onerror = () => reject(transaction.error);
  });
};

export {
  openDB,
  savePDFDebounced,
  savePDFToDB,
  saveAnnotationToDB,
  getPDFFromDB,
  getAnnotationsFromDB,
  removeAnnotationFromDB,
  clearAnnotationsFromDB,
  clearPDFInDB,
  updateAnnotationIdInDB
};
