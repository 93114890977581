import React from "react";
import { Box, Typography, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const currentYear = new Date().getFullYear();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        px: 2,
      }}
    >
      <Box sx={{ flex: 1, flexGrow: 1 }}>
        <Typography variant="caption" color="textSecondary">
          {process.env.REACT_APP_SOFTWARE} v{process.env.REACT_APP_VERSION} © {currentYear} · 2m Interactive Sàrl
          | All rights reserved
        </Typography>
      </Box>
      <Box sx={{ flex: 1, flexGrow: 1, textAlign: "right" }}>
        <Link
          href="/privacy"
          target="_blank"
          rel="noreferrer"
          variant="caption"
          color="textSecondary"
        >
          {t("footer.privacyPolicy")}
        </Link>{" "}
        <Typography variant="caption" color="textSecondary">
          |
        </Typography>{" "}
        <Link
          href="/terms"
          target="_blank"
          rel="noreferrer"
          variant="caption"
          color="textSecondary"
        >
          {t("footer.termsOfService")}
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
