// components/core/Navbar.tsx

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Tooltip, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { RootState } from "services/store";
import InfoModal from "components/core/InfoModal/InfoModal";
import CresusAction from "components/actions/CresusAction/CresusAction";
import FullScreenAction from "components/actions/FullScreenAction/FullScreenAction";
import AccessGedAction from "components/actions/AccessGedAction/AccessGedAction";
import LogoutAction from "components/actions/LogoutAction/LogoutAction";
import imageLogo from "assets/images/2mInteractiveLogo.png";
import UserAvatar from "components/core/UserAvatar/UserAvatar";

interface RouteParams {
  ged: string;
}

const Navbar = () => {
  const { t } = useTranslation();
  const { ged } = useParams<RouteParams>();

  const isLogged = useSelector((state: RootState) => state.user.isLogged);
  const clientName = useSelector((state: RootState) => state.user.clientName);

  const [showAuthInfo, setShowAuthInfo] = useState<boolean>(isLogged || false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setShowAuthInfo(isLogged || false);
  }, [isLogged]);

  return (
    <Box
      component="header"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        bgcolor: "white",
        position: "relative",
        justifyContent: "space-between",
        alignItems: "center",
        px: 2,
      }}
      className="drop-shadow-xl"
    >
      <Box sx={{ flex: 1, flexGrow: 1, textAlign: "left" }}>
        <Button
          variant="text"
          sx={{
            position: "fixed",
            mt: "-43px",
            p: 0,
            minWidth: 0,
            "&:hover": { backgroundColor: "transparent" },
            "&:focus": { backgroundColor: "transparent" },
            "&:active": { backgroundColor: "transparent" },
          }}
          onClick={() => setIsInfoModalOpen(true)}
          disableRipple
        >
          <img
            id="header-logo"
            src={imageLogo}
            alt="logo"
            style={{ height: 120 }}
          />
        </Button>
        <InfoModal
          open={isInfoModalOpen}
          handleClose={() => setIsInfoModalOpen(false)}
        />
      </Box>
      <Box sx={{ flex: 1, flexGrow: 1, textAlign: "center" }}>
        <Typography
          variant="h1"
          sx={{ fontSize: "2rem", fontWeight: "bold", color: "#666" }}
        >
          {t("header.title")}
        </Typography>
        <Typography variant="body2" sx={{ color: "#666" }}>
          {clientName}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: 1,
          flexGrow: 1,
          textAlign: "right",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <CresusAction />
        <FullScreenAction />
        {showAuthInfo && (
          <>
            <AccessGedAction />
            <LogoutAction />
          </>
        )}
        <UserAvatar />
      </Box>
    </Box>
  );
};

export default Navbar;
