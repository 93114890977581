import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import { CustomStampSettings } from "@syncfusion/ej2-react-pdfviewer";
import BwipJs from "bwip-js";
import { FC, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AppStatus,
  setStatus,
} from "../../../features/appStateSlice";
import { useTranslation } from "react-i18next";
import { useDialog } from "context/DialogContext";
import { useSnackbar } from "context/SnackbarContext";
import imageCodebar from "../../../assets/images/CODEBAR.png";
import { RootState } from "services/store";
import { usePdfViewer } from "context/PdfViewerContext";
import { useFocusContext } from "context/FocusContext";
import { savePDFDebounced } from "services/indexeddb";
import { checkField, initFields } from "features/doctypesSlice";

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  margin: 5,
});

const BarcodeBtn: FC= () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const { focusField } = useFocusContext();

  const status = useSelector((state: RootState) => state.appState.status);

  const bcCanvaRef = useRef<HTMLCanvasElement>(null);
  const fabRef = useRef<HTMLButtonElement>(null);

  const { pdfViewer, addAnnotation } = usePdfViewer();

  const selectedDoctypeKey = useSelector(
    (state: RootState) => state.doctypes.selectedDoctypeKey
  );
  const doctype = useSelector((state: RootState) =>
    state.doctypes.doctypes.find((doc) => doc.Key === selectedDoctypeKey)
  );

  const authState = useSelector((state: RootState) => state.user);

  const isFieldsValid = () => {
    if (!doctype) return false;
    let allFieldsValid = true;
    doctype.Fields.forEach((field: any) => {
      if (field.Error) {
        allFieldsValid = false;
      }
    });
    return allFieldsValid;
  };

  const checkFields = () => {
    if (!doctype) return false;
    
    doctype.Fields.forEach((field: any) => {
      dispatch(checkField({ doctypeKey: doctype.Key, fieldKey: field.Key }));
    });
  };
  

  const onBarcodeGen = (pageNumber: number) => {
    let formattedData = formatDataInputs();
    if (formattedData !== "") {
      if (bcCanvaRef.current) {
        try {
          let bcCanva = BwipJs.toCanvas(bcCanvaRef.current, {
            bcid: "datamatrix",
            text: formattedData,
            scale: 3,
            height: 10,
            width: 10,
            includetext: true,
            textxalign: "center",
            backgroundcolor: "ffffff",
            showborder: true,
            bordercolor: "ffffff",
            paddingheight: 1,
            paddingwidth: 1,
          });
          let imgAsDataUrl = bcCanva.toDataURL("image/jpeg");
          localStorage.setItem(`qrCode-page${pageNumber}`, imgAsDataUrl);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const formatDataInputs = () => {
    let dataToString = "";
    if (doctype) {
      dataToString += authState.displayBrWithClient
        ? `${selectedDoctypeKey}_${authState.code}|`
        : `${selectedDoctypeKey}|`;
      doctype.Fields.forEach((field: any) => {
        if (field && field.Value && typeof field.Value === "string") {
          dataToString += `${field.Value}|`;
        } else if (field && field.Value && field.Value.Value) {
          dataToString += `${field.Value.Value}|`;
        } else {
          dataToString += `|`;
        }
      });
    }
    return dataToString;
  };

  const generateStamp = async (e: React.MouseEvent<HTMLElement>) => {
    if (!pdfViewer) return;
    if (!isFieldsValid()) {
      showDialog({
        type: "base",
        title: t("modal.errorOccurred"),
        message: t("dialog.validationFailed"),
        severity: "error",
      });
      return;
    }

    if (hasPageIndexContainsBR(pdfViewer.currentPageNumber)) {
      showDialog({
        type: "base",
        title: t("modal.errorOccurred"),
        message: t("dialog.barcodeDetected"),
        severity: "error",
      });
    } else {
      let formattedData = formatDataInputs();
      onBarcodeGen(pdfViewer.currentPageNumber);

      let imgUrl: string | null = null;
      let imgKey = `qrCode-page${pdfViewer.currentPageNumber}`;
      imgUrl = localStorage.getItem(imgKey);

      if (imgUrl) {
        addAnnotation("Stamp", {
          author: `${process.env.REACT_APP_AUTHOR}|${formattedData}`,
          isPrint: true,
          offset: { x: 10, y: 10 },
          height: 40,
          width: 40,
          pageNumber: pdfViewer.currentPageNumber,
          customStamps: [
            {
              customStampName: process.env.REACT_APP_STAMP_NAME,
              customStampImageSource: imgUrl,
            },
          ],
        } as CustomStampSettings);
      }

      localStorage.removeItem(imgKey);
      dispatch(setStatus(AppStatus.EDITING));
      dispatch(initFields());
      focusField(0);
      // resetFields();
      // savePDFDebounced(await pdfViewer.saveAsBlob());

      showSnackbar({
        message: t("barcodeAdded", { pageNumber: pdfViewer.currentPageNumber }),
        severity: "success",
        duration: 3000,
      });
    }
  };

  const hasPageIndexContainsBR = (pageNumber: number) => {
    if (!pdfViewer) return false;
    const annotation = pdfViewer.annotationCollection.find(
      (annotation) => annotation.author.startsWith(process.env.REACT_APP_AUTHOR) && annotation.pageNumber + 1 === pageNumber
    );
    return annotation !== undefined;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === "Tab" && !e.shiftKey) {
      e.preventDefault();
      focusField(0);
    }
  };

  const onFocus = () => {
    checkFields()
  }

  return (
    <>
      <Fab
        variant="extended"
        color="primary"
        sx={{ mt: 2, zIndex: 5, width: "100%" }}
        onClick={generateStamp}
        ref={fabRef}
        onKeyDown={handleKeyDown}
        onFocus={onFocus}
      >
        <ImageSrc style={{ backgroundImage: `url(${imageCodebar})` }} />
      </Fab>
      <div>
        <canvas ref={bcCanvaRef} id="bcCanvas" style={{ width: 0 }}></canvas>
      </div>
    </>
  );
};

export default BarcodeBtn;
