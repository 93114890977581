import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
} from "react-router-dom";
import { ThemeProvider, createTheme, Box } from "@mui/material";
import type {} from '@mui/lab/themeAugmentation';

import "./App.css";

import EDocIndexWeb from "pages/EDocIndexWeb/EDocIndexWeb";
import Login from "pages/Login/Login";
import PrivacyPage from "pages/PrivacyPage/PrivacyPage";
import TermsPage from "pages/TermsPage/TermsPage";
import NotFoundPage from "pages/NotFoundPage/NotFoundPage";

import Navbar from "components/core/Navbar/Navbar";
import Footer from "components/core/Footer/Footer";
import { SnackbarProvider } from "context/SnackbarContext";
import { DialogProvider } from "context/DialogContext";

const theme = createTheme({
  typography: {
    fontFamily: "'Montserrat',Helvetica,Arial,Lucida,sans-serif",
  },
  palette: {
    primary: {
      main: "#366193",
    },
    secondary: {
      main: "#1b3048",
    },
    info: {
      main: "#366193",
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "0.9rem",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          backgroundColor: "#366193",
          color: "white",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "8px",
        },
      },
    },
  },
});

const AppLayout = ({ children }: { children: React.ReactNode }) => (
  <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
    <Box
      sx={{
        height: 100,
        width: "100%",
        flexShrink: 0,
        position: "fixed",
        zIndex: 100,
      }}
    >
      <Navbar />
    </Box>
    <Box
      sx={{
        flex: 1,
        background: "#366193",
        minHeight: "calc(100vh - 135px)",
        mt: "100px",
      }}
    >
      {children}
    </Box>
    <Box sx={{ height: 35 }}>
      <Footer />
    </Box>
  </Box>
);

const PageLayout = ({ children }: { children: React.ReactNode }) => (
  <Box sx={{ display: "flex", flexDirection: "column" }}>
    <Box sx={{ height: 100, width: "100%", flexShrink: 0, position: "fixed" }}>
      <Navbar />
    </Box>
    <Box
      sx={{
        flex: 1,
        background: "#366193",
        color: "white",
        minHeight: "calc(100vh - 135px)",
        mt: "100px",
      }}
    >
      {children}
    </Box>
    <Box sx={{ height: 35 }}>
      <Footer />
    </Box>
  </Box>
);

interface GedRouteProps {
  component: React.FC<{ ged: string }>;
}

const GedRoute: React.FC<GedRouteProps> = ({ component: Component }) => {
  const { ged } = useParams<{ ged: string }>();
  return <Component ged={ged} />;
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <DialogProvider>
          <Router>
            <Switch>
              <Route exact path="/privacy">
                <PageLayout>
                  <PrivacyPage />
                </PageLayout>
              </Route>
              <Route exact path="/terms">
                <PageLayout>
                  <TermsPage />
                </PageLayout>
              </Route>
              <Route path="/:ged">
                <AppLayout>
                  <Switch>
                    <Route
                      exact
                      path="/:ged/login"
                      component={() => <GedRoute component={Login} />}
                    />
                    <Route
                      exact
                      path="/:ged/edocindexweb"
                      component={() => (
                        <GedRoute component={EDocIndexWeb} />
                        // <PDFBarcodeListContext.Provider value={pdfBarcodeList}>
                        //   <pdfDataContext.Provider value={pdfData}>
                        //   </pdfDataContext.Provider>
                        // </PDFBarcodeListContext.Provider>
                      )}
                    />
                    <Route
                      exact
                      path="/:ged"
                      component={() => <GedRoute component={Login} />}
                    />
                    <Route path="*" component={NotFoundPage} />
                  </Switch>
                </AppLayout>
              </Route>
              <AppLayout>
                <Route component={NotFoundPage} />
              </AppLayout>
            </Switch>
          </Router>
        </DialogProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
