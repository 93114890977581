// components/actions/LogoutAction/LogoutAction.tsx

import React from "react";
import { Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Image from "components/core/Image/Image";
import imageLogout from "assets/images/LOGOUT.png";
import { RootState } from "services/store";
import { logout } from "features/userSlice";
import api from "services/api";
import { useSnackbar } from "context/SnackbarContext";

interface RouteParams {
  ged: string;
}

const LogoutAction: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { ged } = useParams<RouteParams>();
  const { showSnackbar } = useSnackbar();
  const pdfFileURI = useSelector(
    (state: RootState) => state.appState.pdfFileURI
  );

  const performLogout = async () => {
    if (pdfFileURI) {
      try {
        const formData = new FormData();
        formData.append("FileName", pdfFileURI);
        await api.post("/pdffile/unlock", formData);
      } catch (error) {
        console.error(error);
      }
    }
    localStorage.removeItem("token");
    showSnackbar({ message: t("header.logoutMessage"), severity: "success" });
    dispatch(logout());
    history.push(`/${ged}/login`);
  };

  return (
    <Tooltip title={t("header.logout")} arrow>
      <Button
        onClick={performLogout}
        variant="contained"
        startIcon={<Image style={{ backgroundImage: `url(${imageLogout})` }} />}
        size="large"
        sx={{ ml: 1, height: 40, width: 30 }}
      />
    </Tooltip>
  );
};

export default LogoutAction;
