import React, { createContext, useContext, useState, ReactNode, forwardRef, useRef } from "react";

// Créer un contexte pour la gestion des références
const FocusContext = createContext<{
  registerFieldRef: (index: number, ref: HTMLInputElement | null) => void;
  registerFocusRef: (key: string, ref: HTMLInputElement | null) => void;
  focusField: (index: number) => void;
  focusFocus: (key: string) => void;
} | null>(null);

export const FocusProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const fieldRefs = useRef<(HTMLInputElement | null)[]>([]);
  const focusRefs = useRef<any>({});

  const registerFieldRef = (index: number, ref: HTMLInputElement | null) => {
    fieldRefs.current[index] = ref;
  };

  const registerFocusRef = (key: string, ref: HTMLInputElement | null) => {
    focusRefs.current[key] = ref;
  };

  const focusField = (index: number) => {
    if (fieldRefs.current[index]) {
      fieldRefs.current[index]?.focus();
    }
  };
  
  const focusFocus = (key: string) => {
    if (focusRefs.current[key]) {
      focusRefs.current[key]?.focus();
    }
  };

  return (
    <FocusContext.Provider value={{ registerFieldRef, registerFocusRef, focusField, focusFocus }}>
      {children}
    </FocusContext.Provider>
  );
};

export const useFocusContext = () => {
  const context = useContext(FocusContext);
  if (!context) {
    throw new Error("useFocusContext must be used within a FocusProvider");
  }
  return context;
};
