import React from "react";
import { Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import Image from "components/core/Image/Image";
import imageGed from "assets/images/GED.png";
import { useSelector } from "react-redux";
import { RootState } from "services/store";

const AccessGedAction: React.FC = () => {
  const { t } = useTranslation();
  const gedLink = useSelector((state: RootState) => state.user.gedLink);

  const openAlfresco = () => {
    window.open(gedLink);
  };

  return (
    <Tooltip title={t("header.accessGED")} arrow>
      <Button
        onClick={openAlfresco}
        variant="contained"
        startIcon={<Image style={{ backgroundImage: `url(${imageGed})` }} />}
        size="large"
        sx={{ ml: 1, height: 40, width: 30 }}
      />
    </Tooltip>
  );
};

export default AccessGedAction;
