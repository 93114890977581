// features/appStateSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBarcodeListData } from 'models/IBarcodeListData';

enum AppStatus {
  NO_DOCUMENT = 'NO_DOCUMENT',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  EDITING = 'EDITING'
}

interface AppState {
  status: AppStatus;
  pdfFileURI: string;
  currentPage: number | null;
  displayNavigator: boolean;
}

const initialState: AppState = {
  status: AppStatus.NO_DOCUMENT,
  pdfFileURI: '',
  currentPage: null,
  displayNavigator: false,
};

const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setStatus(state, action: PayloadAction<AppStatus>) {
      state.status = action.payload;
    },
    setPdfFileURI(state, action: PayloadAction<string>) {
      state.pdfFileURI = action.payload;
    },
    setCurrentPage(state, action: PayloadAction<number | null>) {
      state.currentPage = action.payload;
    },
    setDisplayNavigator(state, action: PayloadAction<boolean>) {
      state.displayNavigator = action.payload;
    }
  },
});

export const {
  setStatus,
  setPdfFileURI,
  setCurrentPage,
  setDisplayNavigator
} = appStateSlice.actions;

export default appStateSlice.reducer;

export { AppStatus };
