import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { addOption, checkField, updateField } from "features/doctypesSlice";
import { useDialog } from "context/DialogContext";
import { useSnackbar } from "context/SnackbarContext";
import { useFocusContext } from "context/FocusContext";
import api from "services/api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface AutoCompleteFieldProps {
  field: any;
  doctypeKey: string;
  inputRef?: React.Ref<HTMLInputElement>;
}

const AutoCompleteField: React.FC<AutoCompleteFieldProps> = ({
  field,
  doctypeKey,
  inputRef,
}) => {
  const { t } = useTranslation();
  const { showDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const { focusField } = useFocusContext();
  const dispatch = useDispatch();

  const [lastInputValueAsked, setLastInputValueAsked] = useState<string | null>(null); // State to store the last input value asked

  const check = () => {
    dispatch(checkField({ doctypeKey, fieldKey: field.Key }));
  };

  const addNewOption = async (newValue: string) => {
    try {
      const res = await api.post(`/libtech/${field.Key}`, {
        value: newValue,
      });
      showSnackbar({
        message: res.data.Message,
        severity: "success",
      });
      dispatch(
        addOption({
          doctypeKey,
          fieldKey: field.Key,
          newOption: { Value: newValue, Label: newValue },
        })
      );
    } catch (error: any) {
      console.error(error);
      showSnackbar({
        message: error.data
          ? error.data.Message || t("internalError")
          : t("internalError"),
        severity: "error",
      });
    }
  };

  const onChange = (event: React.ChangeEvent<{}>, newValue: string | null) => {
    dispatch(
      updateField({
        doctypeKey,
        fieldProperty: "Value",
        fieldKey: field.Key,
        value: newValue || "",
      })
    );
  };

  const onInputChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (field.Fixed) {
      dispatch(
        updateField({
          doctypeKey,
          fieldProperty: "InputValue",
          fieldKey: field.Key,
          value: newValue,
        })
      );
    } else {
      dispatch(
        updateField({
          doctypeKey,
          fieldProperty: "InputValue",
          fieldKey: field.Key,
          value: newValue,
        })
      );
      dispatch(
        updateField({
          doctypeKey,
          fieldProperty: "Value",
          fieldKey: field.Key,
          value: newValue,
        })
      );
    }
  };

  const refocusInput = () => {
    focusField(field.Pos - 1);
  };

  const onBlur = async () => {
    check();
    if (field.Customizable) {
      const userInput = field.InputValue;
      if (userInput) {
        const findOption = field.Options.find(
          (option: any) =>
            option.Value.toLowerCase() === userInput.toLowerCase() ||
            option.Label.toLowerCase() === userInput.toLowerCase()
        );
        if (!findOption && userInput.toLowerCase() !== lastInputValueAsked?.toLowerCase()) {
          await showDialog({
            type: "confirmation",
            title: t("confirmation.title"),
            message: `${t("autocomplete.confirmAddValue")}<br /><br />${userInput}`,
            severity: "info",
            onConfirm: () => {
              addNewOption(userInput);
            },
            onCancel: () => {},
          });
          refocusInput();
          setLastInputValueAsked(userInput);
        }
      }
    }
  };

  return (
    <Autocomplete
      openOnFocus
      freeSolo={field.Customizable || !field.Fixed}
      options={field.Options || []}
      getOptionLabel={(option: any) =>
        typeof option === "string" ? option : option.Label
      }
      noOptionsText={t("fieldNoOptions")}
      value={field.Value || ""}
      inputValue={field.InputValue || ""}
      popupIcon={<ExpandMoreIcon />}
      forcePopupIcon={field && field.Options && field.Options.length > 0}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={inputRef}
          label={field.Label}
          variant="standard"
          required={field.Required}
          error={field.Error !== undefined && field.Error !== null}
          helperText={t(field.Error) || ""}
          onBlur={onBlur}
        />
      )}
      onChange={onChange}
      onInputChange={onInputChange}
    />
  );
};

export default AutoCompleteField;
