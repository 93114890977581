import React, { forwardRef } from "react";
import { TextField, FormControl } from "@mui/material";
import { useTranslation } from "react-i18next";
import { checkField, updateField } from "features/doctypesSlice";
import { useDispatch } from "react-redux";

interface NumberFieldProps {
  field: any;
  doctypeKey: string;
  inputRef?: React.Ref<HTMLInputElement>;
}

const NumberField: React.FC<NumberFieldProps> = ({
  field,
  doctypeKey,
  inputRef,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const check = () => {
      dispatch(checkField({ doctypeKey, fieldKey: field.Key }));
    };

    const formatValue = (value: string) => {
      if (value) {
        return parseFloat(value).toFixed(2);
      }
      return "";
    };

    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;

      // Ensure only valid numbers (no scientific notation)
      if (/^\d*\.?\d*$/.test(newValue)) {
        dispatch(
          updateField({
            doctypeKey,
            fieldProperty: "InputValue",
            fieldKey: field.Key,
            value: newValue,
          })
        );
        dispatch(
          updateField({
            doctypeKey,
            fieldProperty: "Value",
            fieldKey: field.Key,
            value: newValue,
          })
        );
      }
    };

    const handleNumberBlur = () => {
      const formattedValue = formatValue(field.Value || "");
      dispatch(
        updateField({
          doctypeKey,
          fieldProperty: "InputValue",
          fieldKey: field.Key,
          value: formattedValue,
        })
      );
      dispatch(
        updateField({
          doctypeKey,
          fieldProperty: "Value",
          fieldKey: field.Key,
          value: formattedValue,
        })
      );
      check();
    };

    return (
      <FormControl fullWidth>
        <TextField
        inputRef={inputRef}
          type="text"
          variant="standard"
          label={field.Label}
          required={field.Required}
          error={field.Error !== undefined && field.Error !== null}
          helperText={t(field.Error) || ""}
          value={field.InputValue || ""}
          onChange={handleNumberChange}
          onBlur={handleNumberBlur}
          placeholder="0.00"
        />
      </FormControl>
    );
  }

export default NumberField;
