import React, { FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "services/store";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { usePdfViewer } from "context/PdfViewerContext";
import { useTranslation } from "react-i18next";
import { setCurrentPage, setDisplayNavigator } from "features/appStateSlice";

const RightSheetDialog: FC = () => {
  const { t } = useTranslation();
  const currentPage = useSelector(
    (state: RootState) => state.appState.currentPage
  );
  const { pdfViewer, goToPage, annotationChanged } = usePdfViewer();
  const dispatch = useDispatch();

  const containerRef = useRef<HTMLDivElement>(null);
  const activeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activeRef.current && containerRef.current) {
      containerRef.current.scrollTo({
        top:
          activeRef.current.offsetTop -
          containerRef.current.clientHeight / 2 +
          activeRef.current.clientHeight / 2,
        behavior: "smooth",
      });
    }
  }, [currentPage, annotationChanged]);
  
  if (!pdfViewer || !pdfViewer.annotationCollection || !pdfViewer.annotationCollection.length) return null;
  
  const barcodes = pdfViewer.annotationCollection.filter(
    (annotation) => annotation.author.startsWith(process.env.REACT_APP_AUTHOR)
  );
  
  if (!barcodes || !barcodes.length) return null;
  
  barcodes.sort((a, b) => a.pageNumber - b.pageNumber)

  const onClickBrIndicator = (barcode: any) => {
    if (currentPage === barcode.pageNumber + 1) {
      dispatch(setCurrentPage(null));
    } else {
      dispatch(setCurrentPage(barcode.pageNumber + 1));
      goToPage(barcode.pageNumber + 1);
    }
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        position: "fixed",
        padding: "16px",
        height: "calc(100% - 100px - 43px - 16px - 16px - 35px - 40px)",
        right: 16,
        top: 100 + 43 + 16,
        zIndex: 1000,
        pointerEvents: "auto",
        overflowY: "auto",
        scrollbarWidth: "none", // For Firefox
        "&::-webkit-scrollbar": {
          display: "none", // For Chrome, Safari, and Opera
        },
      }}
    >
      {barcodes
        .map((barcode, index) => {
          const isActive = barcode.pageNumber + 1 === currentPage;

          return (
            <Box
              key={index}
              ref={isActive ? activeRef : null}
              sx={{
                display: "flex",
                alignItems: "center",
                pointerEvents: "auto",
                mb: 1,
              }}
            >
              <Tooltip title={`${t("tooltip.goToPage")}`} placement="left">
                <Button
                  color={isActive ? `primary` : `inherit`}
                  onClick={() => onClickBrIndicator(barcode)}
                  variant="contained"
                  sx={{
                    boxShadow: 2,
                    backgroundColor: !isActive ? "white" : "",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {barcode.pageNumber + 1}
                </Button>
              </Tooltip>
            </Box>
          );
        })}
    </Box>
  );
};

export default RightSheetDialog;
