import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "services/store"; // Adjust the import path to your store
import { IDoctypeField } from "features/doctypesSlice"; // Adjust the import path to your slice
import { Typography, Box } from "@mui/material";
import AutoCompleteField from "../../inputs/AutoCompleteField/AutoCompleteField";
import FavoriteButtons from "components/inputs/FavoriteButtons/FavoriteButtons";
import DatePickerField from "components/inputs/DatePickerField/DatePickerField";
import NumberField from "components/inputs/NumberField/NumberField";
import { useFocusContext } from "context/FocusContext";

const DoctypeFormComponent: React.FC = () => {
  const selectedDoctypeKey = useSelector(
    (state: RootState) => state.doctypes.selectedDoctypeKey
  );
  const doctype = useSelector((state: RootState) =>
    state.doctypes.doctypes.find((doc) => doc.Key === selectedDoctypeKey)
  );

  const { registerFieldRef } = useFocusContext();

  if (!doctype) {
    return <Typography>No doctype selected or available.</Typography>;
  }

  return (
    <Box>
      {doctype.Fields.filter(
        (field: IDoctypeField) => field.Display && field.Type !== "NONE"
      ).map((field: IDoctypeField, index: number) => {
        // Determine the watchField based on WatchPos
        const watchField =
          field.WatchPos !== -1
            ? doctype.Fields.find((f: IDoctypeField) => f.Pos === field.WatchPos)
            : null;
  
        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              my: 1.0,
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              {field.Type === "AUTOCOMPLETE" ? (
                <AutoCompleteField field={field} doctypeKey={doctype.Key}  inputRef={(ref) => registerFieldRef(index, ref)} />
              ) : field.Type === "DATE" ? (
                <DatePickerField
                  field={field}
                  doctypeKey={doctype.Key}
                  watchField={watchField}
                  inputRef={(ref) => registerFieldRef(index, ref)}
                />
              ) : field.Type === "NUMBER" ? (
                <NumberField field={field} doctypeKey={doctype.Key}  inputRef={(ref) => registerFieldRef(index, ref)} />
              ) : (
                <Typography variant="h6">{field.Label}</Typography>
              )}
            </Box>
            <FavoriteButtons field={field} doctypeKey={doctype.Key} />
          </Box>
        );
      })}
    </Box>
  );
  
};

export default DoctypeFormComponent;
