export const readFile = async (
  file: File | null,
  type: "text" | "dataURL" | "arrayBuffer"
): Promise<string | ArrayBuffer> => {
  return new Promise<string | ArrayBuffer>((resolve, reject) => {
    const fileReader = new FileReader();
    
    fileReader.onload = (e) => {
      if (e && e.target && e.target.result && file !== null) {
        resolve(e.target.result);
      } else {
        reject(new Error("Failed to read file"));
      }
    };

    fileReader.onerror = () => {
      reject(new Error("Failed to read file"));
    };

    if (file !== null) {
      if (type === "text") {
        fileReader.readAsText(file);
      } else if (type === "dataURL") {
        fileReader.readAsDataURL(file);
      } else if (type === "arrayBuffer") {
        fileReader.readAsArrayBuffer(file);
      }
    } else {
      reject(new Error("No file provided"));
    }
  });
};
