import { FC, useState, useEffect } from "react";
import { Box, IconButton, Tooltip, CircularProgress } from "@mui/material";
import styled from "@emotion/styled";
import imageStar from "../../../assets/images/STAR.png";
import imageStarFilled from "../../../assets/images/STAR_FILLED.png";
import imageClose from "../../../assets/images/CLOSE.png";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "../../../context/SnackbarContext";
import api from "services/api";
import { useSelector } from "react-redux";
import { RootState } from "services/store";
import { checkField, updateField } from "features/doctypesSlice"; // Adjust the import path to your slice
import { useDispatch } from "react-redux";

const Image = styled("img")({
  width: 24,
  height: 24,
});

interface FavoriteButtonsProps {
  field: any;
  doctypeKey: string;
}

const FavoriteButtons: FC<FavoriteButtonsProps> = ({
  field,
  doctypeKey,
}) => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState(field.Default);
  const selectedDoctypeKey = useSelector((state: RootState) => state.doctypes.selectedDoctypeKey);
  const dispatch = useDispatch();

  useEffect(() => {
    if (field.Type === "AUTOCOMPLETE" && field.Default) {
      const option = field.Options.find(
        (option: any) =>
          option.Value.toLowerCase() === field.Default.toLowerCase() ||
          option.Label.toLowerCase() === field.Default.toLowerCase()
      );
      if (option) {
        setTooltipTitle(option.Label);
      } else {
        setTooltipTitle(field.Default);
      }
    } else {
      setTooltipTitle(field.Default);
    }
  }, [field, field.Default, field.Options]);

  const onAddFavorite = async (): Promise<void> => {
    const value = field.Value;
    if (value) {
      setIsLoadingAdd(true);
      let postValue = "";
      if (field.Type === "AUTOCOMPLETE") {
        if (typeof value === "string") {
          postValue = value;
        } else {
          postValue = value.Value;
        }
      } else if (field.Type === "DATE") {
        postValue = value;
      } else if (field.Type === "NUMBER") {
        postValue = value;
      }
      try {
        const response = await Promise.all([
          api.put(`/user-config/default-values/${selectedDoctypeKey}`, {
            posId: `${field.Pos}`,
            value: postValue,
          }),
          new Promise((resolve) => setTimeout(resolve, 500)),
        ]);
        dispatch(
          updateField({
            doctypeKey,
            fieldProperty: "Default",
            fieldKey: field.Key,
            value: postValue,
          })
        );
        showSnackbar({
          message: response[0].data.Message,
          severity: "success",
        });
      } catch (error: any) {
        console.error("Error adding favorite", error);
        showSnackbar({
          message: error.data.Message || t("internalError"),
          severity: "error",
        });
      } finally {
        setIsLoadingAdd(false);
      }
    } else {
      showSnackbar({
        message: t("favorite.fieldNotValid"),
        severity: "error",
      });
    }
  };

  const onRemoveFavorite = async (): Promise<void> => {
    setIsLoadingRemove(true);
    try {
      const response = await Promise.all([
        api.put(`/user-config/default-values/${selectedDoctypeKey}`, {
          posId: `${field.Pos}`,
          value: "",
        }),
        new Promise((resolve) => setTimeout(resolve, 500)),
      ]);
      

      dispatch(
        updateField({
          doctypeKey,
          fieldProperty: "Default",
          fieldKey: field.Key,
          value: null,
        })
      );
      showSnackbar({
        message: response[0].data.Message,
        severity: "success",
      });
    } catch (error: any) {
      console.error("Error removing favorite", error);
      showSnackbar({
        message: error.data.Message || t("internalError"),
        severity: "error",
      });
    } finally {
      setIsLoadingRemove(false);
    }
  };

  const onApplyFavorite = (): void => {
    const defaultValue = field.Default;
    if (defaultValue) {
      if (field.Type === "AUTOCOMPLETE") {
        const option = field.Options.find(
          (option: any) =>
            option.Value.toLowerCase() === defaultValue.toLowerCase() ||
            option.Label.toLowerCase() === defaultValue.toLowerCase()
        );
        if (option) {
          dispatch(
            updateField({
              doctypeKey,
              fieldProperty: "Value",
              fieldKey: field.Key,
              value: option,
            })
          );
          dispatch(
            updateField({
              doctypeKey,
              fieldProperty: "InputValue",
              fieldKey: field.Key,
              value: option.Label,
            })
          );
        } else {
          dispatch(
            updateField({
              doctypeKey,
              fieldProperty: "Value",
              fieldKey: field.Key,
              value: defaultValue,
            })
          );
          dispatch(
            updateField({
              doctypeKey,
              fieldProperty: "InputValue",
              fieldKey: field.Key,
              value: defaultValue,
            })
          );
        }
      } else {
        dispatch(
          updateField({
            doctypeKey,
            fieldProperty: "Value",
            fieldKey: field.Key,
            value: defaultValue,
          })
        );
        dispatch(
          updateField({
            doctypeKey,
            fieldProperty: "InputValue",
            fieldKey: field.Key,
            value: defaultValue,
          })
        );
      }

      dispatch(checkField({ doctypeKey, fieldKey: field.Key }));
    }
  };

  return (
    <Box sx={{ mt: 2, ml: 1 }}>
      {field.Default ? (
        <>
          {/* <Tooltip title={t("favorite.apply", { value: tooltipTitle })} arrow> */}
          <Tooltip title={tooltipTitle} arrow>
            <span>
              <IconButton
                disabled={isLoadingAdd || isLoadingRemove}
                onClick={onApplyFavorite}
                size="small"
                tabIndex={-1}
              >
                {isLoadingAdd ? (
                  <CircularProgress size={24} />
                ) : (
                  <Image src={imageStarFilled} alt="Favorite" />
                )}
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t("autocomplete.removeFavorite")} arrow>
            <span>
              <IconButton
                onClick={onRemoveFavorite}
                size="small"
                tabIndex={-1}
                disabled={isLoadingAdd || isLoadingRemove}
              >
                {isLoadingRemove ? (
                  <CircularProgress size={24} />
                ) : (
                  <Image src={imageClose} alt="Remove Favorite" />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip title={t("autocomplete.addFavorite")} arrow>
            <span>
              <IconButton
                onClick={onAddFavorite}
                size="small"
                tabIndex={-1}
                disabled={isLoadingAdd || isLoadingRemove}
              >
                {isLoadingAdd ? (
                  <CircularProgress size={24} />
                ) : (
                  <Image src={imageStar} alt="Add Favorite" />
                )}
              </IconButton>
            </span>
          </Tooltip>
          <span>
            <IconButton size="small" disabled tabIndex={-1}>
              <Image src={imageClose} alt="Remove Favorite" />
            </IconButton>
          </span>
        </>
      )}
    </Box>
  );
};

export default FavoriteButtons;
