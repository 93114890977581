import { configureStore } from "@reduxjs/toolkit";
import userReducer from "features/userSlice";
import appStateReducer from "features/appStateSlice";
import doctypesReducer from "features/doctypesSlice";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";

export const store = configureStore({
  reducer: {
    user: userReducer,
    appState: appStateReducer,
    doctypes: doctypesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(createStateSyncMiddleware()),
});

initMessageListener(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

