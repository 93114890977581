// components/infos/UserInfo/UserInfo.tsx

import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Avatar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { RootState } from "services/store";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next"; // Import useTranslation

interface UserInfoProps {
  open: boolean;
  handleClose: () => void;
}

const UserInfo: React.FC<UserInfoProps> = ({ open, handleClose }) => {
  const { t } = useTranslation(); // Initialize the translation function

  const username = useSelector((state: RootState) => state.user.username);
  const clientName = useSelector((state: RootState) => state.user.clientName);
  const token = useSelector((state: RootState) => state.user.token);

  const [displayName, setDisplayName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);

  useEffect(() => {
    if (token) {
      const decodedToken: any = jwtDecode(token);

      // Extract and set user details
      setDisplayName(decodedToken.displayName || null);
      setEmail(decodedToken.email || null);
    }
  }, [token]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="user-info-title"
      aria-describedby="user-info-description"
    >
      <Box
        sx={{
          position: "absolute",
          minWidth: "354px",
          maxWidth: "512px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          py: 6,
          pb: 4,
          px: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: 1,
        }}
      >
        <IconButton
          autoFocus
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>

        <Avatar sx={{ bgcolor: "#366193", width: 60, height: 60, mb: 2 }}>
          <AccountCircleIcon sx={{ width: 40, height: 40 }} />
        </Avatar>

        {clientName && (
          <Typography variant="h6" id="user-info-description" sx={{ mt: 1, mb: 2, textAlign: "center" }}>
            {clientName}
          </Typography>
        )}

        {username && (
          <>
            <Typography variant="caption" sx={{ color: "text.secondary" }}>
              {t("userInfo.username")}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, textAlign: "center" }}>
              {username}
            </Typography>
          </>
        )}

        {displayName && (
          <>
            <Typography variant="caption" sx={{ color: "text.secondary" }}>
              {t("userInfo.name")}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, textAlign: "center" }}>
              {displayName}
            </Typography>
          </>
        )}

        {email && (
          <>
            <Typography variant="caption" sx={{ color: "text.secondary" }}>
              {t("userInfo.email")}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, textAlign: "center" }}>
              {email}
            </Typography>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default UserInfo;
