import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import api from "services/api";

// Define the types for DoctypeData and IDoctypeField

export interface IDoctypeField {
  Key: string;
  Label: string;
  Required: boolean;
  Display: boolean;
  Value: string;
  InputValue: string;
  Default: string | null;
  Options: any;
  Type: string;
  Customizable: boolean;
  Fixed: boolean;
  Error: string | null;
  WatchPos: number;
  Pos: number;
  Init: boolean;
}

export interface DoctypeData {
  Key: string;
  Title: string;
  Fields: IDoctypeField[];
}

interface UpdateFieldPayload {
  doctypeKey: string;
  fieldProperty: string;
  fieldKey: string;
  value: any;
}

interface CheckFieldPayload {
  doctypeKey: string;
  fieldKey: string;
}

interface DoctypesState {
  doctypes: DoctypeData[];
  selectedDoctypeKey: string | null;
}

interface AddOptionPayload {
  doctypeKey: string;
  fieldKey: string;
  newOption: { Value: string; Label: string };
}

// Define the initial state using the DoctypesState interface
const initialState: DoctypesState = {
  doctypes: [],
  selectedDoctypeKey: null,
};

const doctypesSlice = createSlice({
  name: "doctypes",
  initialState,
  reducers: {
    setDoctypes(state, action: PayloadAction<DoctypeData[]>) {
      state.doctypes = action.payload;
    },
    setSelectedDoctypeKey(state, action: PayloadAction<string | null>) {
      state.selectedDoctypeKey = action.payload;
    },
    putDoctype(state, action: PayloadAction<DoctypeData>) {
      const index = state.doctypes.findIndex(
        (doctype) => doctype.Key === action.payload.Key
      );
      if (index !== -1) {
        state.doctypes[index] = action.payload;
      } else {
        state.doctypes.push(action.payload);
      }
    },
    updateField(state, action: PayloadAction<UpdateFieldPayload>) {
      const { doctypeKey, fieldProperty, fieldKey, value } = action.payload;
      const doctype = state.doctypes.find((doc) => doc.Key === doctypeKey);
      if (doctype) {
        const field = doctype.Fields.find((f) => f.Key === fieldKey);
        if (field) {
          (field as any)[fieldProperty] = value;
        }
      }
    },
    initFields(state) {
      const doctype = state.doctypes.find(
        (doc) => doc.Key === state.selectedDoctypeKey
      );
      if (doctype) {
        doctype.Fields = doctype.Fields.map((field: IDoctypeField) => {
          let resetField = {
            ...field,
            Value: "",
            InputValue: "",
            Error: null,
            Init: true,
          };
          if (field.Default) {
            if (field.Type === "DATE" || field.Type === "NUMBER") {
              resetField.Value = field.Default;
              resetField.InputValue = field.Default;
            } else if (field.Type === "AUTOCOMPLETE") {
              if (field.Fixed) {
                const lowerCaseDefault = field.Default.toLowerCase();
                const option = field.Options.find(
                  (option: any) =>
                    option.Value.toLowerCase() ===
                      lowerCaseDefault.toLowerCase() ||
                    option.Label.toLowerCase() ===
                      lowerCaseDefault.toLowerCase()
                );
                if (option) {
                  resetField.Value = option;
                  resetField.InputValue = option.label;
                }
              } else {
                resetField.Value = field.Default;
                resetField.InputValue = field.Default;
              }
            }
            resetField.Init = false;
          }
          return resetField;
        });
      }
    },
    addOption(state, action: PayloadAction<AddOptionPayload>) {
      const { doctypeKey, fieldKey, newOption } = action.payload;
      const doctype = state.doctypes.find((doc) => doc.Key === doctypeKey);
      if (doctype) {
        const field = doctype.Fields.find((f) => f.Key === fieldKey);
        if (field) {
          field.Options.push(newOption);
          field.Options.sort((a: { Label: string }, b: { Label: string }) =>
            a.Label.localeCompare(b.Label)
          );
        }
      }
    },
    checkField(state, action: PayloadAction<CheckFieldPayload>) {
      const { doctypeKey, fieldKey } = action.payload;
      const doctype = state.doctypes.find((doc) => doc.Key === doctypeKey);
      if (doctype) {
        const field: any = doctype.Fields.find((f) => f.Key === fieldKey);
        if (field) {
          let error = null;
          if (field.Required && !field.Value) {
            error = "validation.required";
          }
          if (error == null) {
            if (field.Type === "AUTOCOMPLETE") {
            } else if (field.Type === "DATE") {
              const isValidDate = dayjs(
                field.Value,
                "DD.MM.YYYY",
                true
              ).isValid();
              if (!isValidDate && field.Value !== "") {
                error = "validation.dateInvalid";
              }
            } else if (field.Type === "NUMBER") {
            }
          }
          if (error == null) {
            if (field && field.Value && typeof field.Value === "string" && field.Value.includes("|")) {
              error = "validation.pipeUsageNotAutorised";
            } else if (field && field.Value && field.Value.Value && field.Value.Value.includes("|")) {
              error = "validation.pipeUsageNotAutorised";
            } 
          }
          (field as any)["Error"] = error;
        }
      }
    },
  },
});

export const {
  setDoctypes,
  setSelectedDoctypeKey,
  putDoctype,
  updateField,
  initFields,
  addOption,
  checkField,
} = doctypesSlice.actions;

export default doctypesSlice.reducer;
