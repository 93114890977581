import React, { createContext, useContext, useState, ReactNode, forwardRef, useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Slide,
  SlideProps,
  Alert,
  AlertTitle,
  CircularProgress,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import gifLoading from "../assets/gifs/LOADING.gif";

type DialogOptions = {
  type: "base" | "lock" | "confirmation";
  title: string;
  message: string;
  severity: "success" | "error" | "warning" | "info";
  progress?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  refElement?: HTMLElement;
};

type DialogContextType = {
  showDialog: (options: DialogOptions) => Promise<boolean>;
  closeDialog: () => void;
  forceCloseDialog: () => void;
};

const DialogContext = createContext<DialogContextType | undefined>(undefined);

const SlideTransition = forwardRef(function SlideTransition(
  props: SlideProps,
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const DialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  const [dialogOptions, setDialogOptions] = useState<DialogOptions | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [resolveDialog, setResolveDialog] = useState<(value: boolean) => void>();
  const lastFocusedElement = useRef<HTMLElement | null>(null);

  const showDialog = (options: DialogOptions): Promise<boolean> => {
    return new Promise((resolve) => {
      setDialogOptions({ ...options, progress: options.progress ?? false });
      setResolveDialog(() => resolve);
      setIsOpen(true);
      lastFocusedElement.current = options.refElement || null;
    });
  };

  const closeDialog = () => {
    if (dialogOptions?.type !== "lock") {
      setIsOpen(false); // Close the dialog
    }
  };

  const forceCloseDialog = () => {
    setIsOpen(false); // Force close the dialog
  };

  const handleConfirm = () => {
    closeDialog();
    if (dialogOptions?.onConfirm) {
      dialogOptions.onConfirm();
    }
    if (resolveDialog) {
      resolveDialog(true);
    }
  };

  const handleCancel = () => {
    closeDialog();
    if (dialogOptions?.onCancel) {
      dialogOptions.onCancel();
    }
    if (resolveDialog) {
      resolveDialog(false);
    }
  };

  return (
    <DialogContext.Provider
      value={{ showDialog, closeDialog, forceCloseDialog }}
    >
      {children}
      {dialogOptions && (
        <Dialog
          open={isOpen} // Control the dialog visibility with isOpen state
          onClose={dialogOptions.type === "lock" ? undefined : closeDialog}
          TransitionComponent={SlideTransition}
        >
          <Alert severity={dialogOptions.severity} variant="filled">
            <AlertTitle>{dialogOptions.title}</AlertTitle>
          </Alert>
          <DialogContent>
            <DialogContentText>
              <span
                dangerouslySetInnerHTML={{ __html: dialogOptions.message }}
              />
            </DialogContentText>
            {dialogOptions.progress && (
              <>
                <Box
                  sx={{
                    textAlign: "center",
                    mt: 2,
                    width: "100%",
                  }}
                >
                  <CircularProgress size={32} />
                  {/* <img
                    src={gifLoading}
                    alt="Loading"
                    style={{
                      height: 32,
                      width: 32,
                      display: "inline-block",
                      filter:
                        "brightness(0) saturate(100%) invert(38%) sepia(4%) saturate(26%) hue-rotate(341deg) brightness(100%) contrast(84%)",
                    }}
                  /> */}
                </Box>
              </>
            )}
          </DialogContent>
          {dialogOptions.type !== "lock" && (
            <DialogActions>
              {dialogOptions.type === "confirmation" && (
                <Button onClick={handleCancel} autoFocus={dialogOptions.type === "confirmation"} color={dialogOptions.severity} variant="outlined">
                  {t("button.cancel")}
                </Button>
              )}
              <Button onClick={handleConfirm} autoFocus={dialogOptions.type !== "confirmation"} color={dialogOptions.severity} variant="contained">
                {dialogOptions.type === "confirmation"
                  ? t("button.ok")
                  : t("button.close")}
              </Button>
            </DialogActions>
          )}
        </Dialog>
      )}
    </DialogContext.Provider>
  );
};

export const useDialog = (): DialogContextType => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
};
