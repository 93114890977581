import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const TermsPage = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ py: 4 }}>
      <Container maxWidth="md">
        <Typography variant="h3" sx={{ textAlign: "center", mb: 4 }}>
          {t("footer.termsOfService")}
        </Typography>
        <Typography variant="h5" sx={{ mt: 4, mb: 1 }}>
          Responsabilités des Utilisateurs
        </Typography>
        <Typography variant="body1" paragraph>
          En utilisant notre application, les utilisateurs s'engagent à ne pas
          compromettre la sécurité du service en partageant leur jeton JWT ou en
          utilisant des moyens non autorisés pour accéder aux services. Les
          utilisateurs sont responsables des données qu'ils importent et
          modifient dans l'application.
        </Typography>
        <Typography variant="h5" sx={{ mt: 4, mb: 1 }}>
          Disponibilité du Service
        </Typography>
        <Typography variant="body1" paragraph>
          Nous nous efforçons de maintenir le service accessible en tout temps.
          Cependant, veuillez noter que le service redémarre chaque jour à
          minuit, heure suisse, pendant une durée de 5 minutes. Durant ce temps,
          le service peut être temporairement indisponible. Nous déclinons toute
          responsabilité en cas d'interruptions temporaires ou de perte de
          données liées à ces redémarrages.
        </Typography>

        <Typography variant="h5" sx={{ mt: 4, mb: 1 }}>
          Limitation de Responsabilité
        </Typography>
        <Typography variant="body1" paragraph>
          Notre responsabilité est limitée en cas de dommages résultant de
          l'utilisation de l'application, sauf en cas de faute lourde ou
          intentionnelle. Les utilisateurs sont invités à sauvegarder
          régulièrement leurs données.
        </Typography>
        <Typography variant="h5" sx={{ mt: 4, mb: 1 }}>
          Support Utilisateur
        </Typography>
        <Typography variant="body1" paragraph>
          Nous fournissons un support technique pour aider les utilisateurs en
          cas de problèmes techniques ou de questions. Veuillez nous contacter
          via notre site web pour toute assistance.
        </Typography>
        <Typography variant="h5" sx={{ mt: 4, mb: 1 }}>
          Modification des CGU
        </Typography>
        <Typography variant="body1" paragraph>
          Nous nous réservons le droit de modifier ces conditions générales
          d'utilisation à tout moment. Les utilisateurs seront informés des
          modifications et devront accepter les nouvelles conditions pour
          continuer à utiliser l'application.
        </Typography>
        <Typography variant="h5" sx={{ mt: 4, mb: 1 }}>
          Résiliation
        </Typography>
        <Typography variant="body1" paragraph>
          Nous nous réservons le droit de suspendre ou de résilier l'accès à
          l'application en cas de violation des présentes conditions. Les
          données de l'utilisateur pourront être supprimées en cas de
          résiliation de l'accès.
        </Typography>
      </Container>
    </Box>
  );
};

export default TermsPage;
